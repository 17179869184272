import Notes from './Notes';

export const Home = () => {

    return (
        <div> 
            <Notes/>
        </div>
    )
}
