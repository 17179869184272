import React from 'react'

const About = () => { 
    return (
        <div>
            This is About page
        </div>
    )
}

export default About
