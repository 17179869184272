import React from 'react'

const Signup = () => {
    return (
        <div>
            I am signup
        </div>
    )
}

export default Signup
